var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-view-wrapper" },
    [
      _vm._l(_vm.colors, function (color, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "color-view",
            class: [color == _vm.currentColor ? "active" : ""],
            style: { borderColor: color },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectColor(color)
              },
            },
          },
          [
            _c("div", {
              staticClass: "round-color",
              style: { backgroundColor: color },
            }),
          ]
        )
      }),
      _c("div", { staticClass: "color-view-empty" }),
      _c("div", { staticClass: "color-view-empty" }),
      _c("div", { staticClass: "color-view-empty" }),
      _c("div", { staticClass: "color-view-empty" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }