export const fonts = [
  "宋体",
  "黑体",
  "微软雅黑",
  "楷体",
  "仿宋",
  "Arial",
  "Times-New-Roman",
  "Sans-Serif",
];

export const fontSizes = [
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "24px",
  "28px",
  "32px",
  "38px",
  "40px"
];

export const lineheight = ["1", "1.5", "1.75", "2", "3", "4", "5"];

export const toolBar = [
  //   [
  { header: [false, 1, 2, 3, 4, 5, 6] },
  { size: fontSizes },
  { font: fonts },
  { background: [] },
  { color: [] }, 
  { lineheight: lineheight },
  //   ], //标题
  //   [
  "bold",
  "italic",
  "underline",
  "strike",
  // ], //加粗\斜体\下划线
  //   [
  { align: "" },
  { align: "center" },
  { align: "right" },
  { align: "justify" }, //对齐
  ,
  //   ]
  //   [
  "blockquote",
  "code-block",
  // ]
  //   [
  { list: "ordered" },
  { list: "bullet" },
  { list: "check" },
  // ], //有序无序列表
  //   [
  // ], //背景色
  //   [
  { indent: "-1" },
  { indent: "+1" },
  // ],
  //   [
  "image",
  // "link",
  // ], //图片、链接
  //   [
  "clean",
  // ], //清楚格式（所谓去掉背景色这些花里胡巧）
];
