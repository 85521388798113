var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      !_vm.readonly
        ? [
            _c(
              "el-dropdown",
              {
                staticStyle: { width: "100%", overflow: "hidden" },
                attrs: {
                  placement: "bottom-start",
                  click: "el-dropdown",
                  trigger: "click",
                  "hide-on-click": false,
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "el-dropdown-link" },
                  [
                    _vm.multiple
                      ? [
                          _vm.selectedTags.length == 0 && !_vm.readonly
                            ? _c(
                                "span",
                                { staticClass: "selected-tag-empty" },
                                [_vm._v("请选择标签")]
                              )
                            : _vm._e(),
                          _vm._l(_vm.selectedTags, function (tag, index) {
                            return _c(
                              "el-tag",
                              {
                                key: index,
                                staticClass: "selected-tag",
                                style: { color: tag.fontColor },
                                attrs: {
                                  size: "mini",
                                  closable: !_vm.readonly,
                                  label: tag,
                                  color: tag.color,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.removeSelectedTag(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(tag.name))]
                            )
                          }),
                        ]
                      : [
                          !_vm.selectedRadio && !_vm.readonly
                            ? _c(
                                "span",
                                { staticClass: "selected-tag-empty" },
                                [_vm._v("请选择标签")]
                              )
                            : _vm._e(),
                          _vm._l(_vm.selectedTags, function (tag, index) {
                            return _c(
                              "el-tag",
                              {
                                key: index,
                                staticClass: "selected-tag",
                                style: { color: tag.fontColor },
                                attrs: {
                                  size: "mini",
                                  closable: !_vm.readonly,
                                  label: tag,
                                  color: tag.color,
                                },
                                on: {
                                  close: function ($event) {
                                    return _vm.removeSelectedTag(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(tag.name))]
                            )
                          }),
                        ],
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ],
                  2
                ),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "200px",
                          display: "inline-block",
                          overflow: "hidden",
                          position: "relative",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pannel-select" },
                          [
                            _vm.multiple
                              ? [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: { change: _vm.updateCheckList },
                                      model: {
                                        value: _vm.checkList,
                                        callback: function ($$v) {
                                          _vm.checkList = $$v
                                        },
                                        expression: "checkList",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { padding: "0px 10px" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              size: "mini",
                                              "prefix-icon": "el-icon-search",
                                            },
                                            model: {
                                              value: _vm.searchTag,
                                              callback: function ($$v) {
                                                _vm.searchTag = $$v
                                              },
                                              expression: "searchTag",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "max-height": "170px",
                                                "overflow-y": "auto",
                                                padding: "5px 0px",
                                                margin: "5px 0px",
                                                "box-sizing": "border-box",
                                              },
                                            },
                                            _vm._l(
                                              _vm.tags,
                                              function (tag, index) {
                                                return _c(
                                                  "el-dropdown-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          tag.name.indexOf(
                                                            _vm.searchTag
                                                          ) >= 0,
                                                        expression:
                                                          "tag.name.indexOf(searchTag)>=0",
                                                      },
                                                    ],
                                                    key: index,
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { label: tag },
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        attrs: {
                                                          label: tag.name,
                                                          value: tag.name,
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "color",
                                                          style: {
                                                            backgroundColor:
                                                              tag.color,
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(tag.name)
                                                            ),
                                                          ]
                                                        ),
                                                        tag.remove &&
                                                        !_vm.readonly &&
                                                        !_vm.selectonly
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "icon",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.removeTag(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-close",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            !_vm.multiple
                              ? [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.updateRadio },
                                      model: {
                                        value: _vm.selectedRadio,
                                        callback: function ($$v) {
                                          _vm.selectedRadio = $$v
                                        },
                                        expression: "selectedRadio",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: { padding: "0px 10px" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "",
                                              size: "mini",
                                              "prefix-icon": "el-icon-search",
                                            },
                                            model: {
                                              value: _vm.searchTag,
                                              callback: function ($$v) {
                                                _vm.searchTag = $$v
                                              },
                                              expression: "searchTag",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "max-height": "170px",
                                                "overflow-y": "auto",
                                                padding: "5px 0px",
                                                margin: "5px 0px",
                                                "box-sizing": "border-box",
                                              },
                                            },
                                            _vm._l(
                                              _vm.tags,
                                              function (tag, index) {
                                                return _c(
                                                  "el-dropdown-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          tag.name.indexOf(
                                                            _vm.searchTag
                                                          ) >= 0,
                                                        expression:
                                                          "tag.name.indexOf(searchTag)>=0",
                                                      },
                                                    ],
                                                    key: index,
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: { label: tag },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: tag.name,
                                                          value: tag.name,
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass: "color",
                                                          style: {
                                                            backgroundColor:
                                                              tag.color,
                                                          },
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(tag.name)
                                                            ),
                                                          ]
                                                        ),
                                                        tag.remove &&
                                                        !_vm.readonly &&
                                                        !_vm.selectonly
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "icon",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.removeTag(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-close",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.tags.length == 0
                              ? _c("div", { staticClass: "empty-tags" }, [
                                  _vm._v(" 暂无标签 "),
                                ])
                              : _vm._e(),
                            !_vm.selectonly
                              ? _c(
                                  "div",
                                  { staticClass: "footer" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: { click: _vm.switchCreate },
                                      },
                                      [_vm._v("新建")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "pannel-create",
                            class: [_vm.pannel == 1 ? "show" : ""],
                          },
                          [
                            _c("div", { staticClass: "pannel-create-label" }, [
                              _vm._v("标签内容"),
                            ]),
                            _c("el-input", {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.newTagName,
                                callback: function ($$v) {
                                  _vm.newTagName = $$v
                                },
                                expression: "newTagName",
                              },
                            }),
                            _c("div", { staticClass: "pannel-create-label" }, [
                              _vm._v("标签颜色"),
                            ]),
                            _c("colorSelector", {
                              model: {
                                value: _vm.newTagColor,
                                callback: function ($$v) {
                                  _vm.newTagColor = $$v
                                },
                                expression: "newTagColor",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "footer" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "mini" },
                                    on: { click: _vm.saveCreate },
                                  },
                                  [_vm._v("保存")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "default", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        _vm.pannel = 0
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._l(_vm.selectedTags, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticClass: "selected-tag",
                style: { color: tag.fontColor },
                attrs: { size: "mini", label: tag, color: tag.color },
              },
              [_vm._v(_vm._s(tag.name))]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }