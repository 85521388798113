<template>
  <vue-editor ref="fieldEditor" useCustomImageHandler :value="value" @image-added="handleImageAdded" :editorOptions="editorSettings" :editor-toolbar="customToolbar" @input="onInput" @text-change="onTxtChanged">
  </vue-editor>
</template>
<script>
// 引入 打包时能把插件打包到主包，
// import { ImageDrop  } from 'quill-image-drop-module'
// import ImageResize from 'quill-image-resize-module'
// import { container, QuillWatch } from 'quill-image-paste-module'

import { getToken } from '@/utils/auth'
import '@/assets/richtext-editor/header.css'
import '@/assets/richtext-editor/fonts.css'
import '@/assets/richtext-editor/fontSize.css'
import '@/assets/richtext-editor/lineHeight.css'

import { lineHeightStyle } from '@/assets/richtext-editor/lineHeight'
import { encodeFileURI } from '@/assets/richtext-editor/richtext-extend-module'
// import CustomClipboard from '@/assets/richtext-editor/clearformat-extend-module'

import { fonts, fontSizes, lineheight, toolBar } from './editorConfig'
import { aliOSSUpload, aliUploader } from '@/utils/oss'
import { getSessionStorageObj } from '@/utils/db'

export default {
  props: {
    value: String,
    Settings: {
      type: Object,
      default: () => { return {} }
    },
    formId:String,
    agencyId:String|Number,
    uploadPath:String,
  },
  data() {
    return {
      formId: '',
      aliOSSUpload: null,
    }
  },
  created() {
  },
  mounted() {

    this.editorSettings = { ...this.editorSettings, ...this.Settings }

  },
  watch: {},
  components: {
    // VueEditor
    VueEditor: (resolve) => {
      //懒加载！！
      require([
        'vue2-editor',
        'quill-image-resize-module',
        '@/assets/richtext-editor/richtext-extend-module',
        // '@/assets/richtext-editor/clearformat-extend-module'
      ], ({ VueEditor, Quill }, ImageResize, { ImageExtend }) => {
        resolve(VueEditor);
        resolve(Quill);

        Quill.register('modules/imageResize', ImageResize.default);
        Quill.register('modules/imageExtend', ImageExtend); //粘贴上传插件

        let fontSizeStyle = Quill.import('attributors/style/size');
        fontSizeStyle.whitelist = fontSizes;
        Quill.register(fontSizeStyle, true);

        var Font = Quill.import('formats/font');
        Font.whitelist = fonts; //将字体加入到白名单
        Quill.register(Font, true);

        Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
      });
    },
  },
  data() {
    return {
      rules: [],
      customToolbar: toolBar, // 富文本编辑器自定义工具栏
      valueChangedFlag: false, // vue2-editor数据值是否改变标志

      editorSettings: {
        theme: 'snow',
        placeholder: '请输入文本...',
        modules: {
          // clipboard: {
          //     // 粘贴过滤
          //     matchers: [[Node.ELEMENT_NODE, ()=> this.HandleCustomMatcher]]
          //   },
          imageResize: {},
          imageExtend: {
            loading: true,
            agencyId:this.agencyId,
            formId:this.formId,
            uploadPath:this.uploadPath
            // name: 'file',
            // size: 20,
            // action: `${process.env.VUE_APP_BASE_API_URL}/ali/upload?folder=fillFile&belongId=${this.formId}`,
            // accept: 'image/jpg, image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
            // response: (res) => {
            //   return encodeFileURI(res.data);
            // },
            // headers: (xhr) => {
            //   xhr.setRequestHeader(
            //     'Authorization',
            //     this.authorization
            //   );
            // },
            // sizeError: (size) => {
            //   this.$message.error(`上传文件不能超过20MB`);
            // },
          },
          toolbar: {
            container: toolBar,
            handlers: {
              lineheight: (value) => {
                if (value) {
                  let quill = this.$refs.fieldEditor.quill;
                  quill.format('lineHeight', value);
                }
              },
            },
          }
        }
      },
      authorization: getToken(),
      uploadUrl: `${process.env.VUE_APP_BASE_API_URL}/ali/upload?folder=fillFile&belongId=${this.formId}`
    }
  },
  methods: {
    // HandleCustomMatcher (node, Delta) {
    //   return node;
    //   // 文字、图片等，从别处复制而来，清除自带样式，转为纯文本
    //   let ops = []
    //   Delta.ops.forEach(op => {
    //     if (op.insert && typeof op.insert === 'string') {
    //       ops.push({
    //         insert: op.insert
    //       })
    //     }else{
    //       ops.push(op)
    //     }
    //   })
    //   Delta.ops = ops
    //   return Delta
    // },
    // toBlob: function(dataurl) {
    //   var arr = dataurl.split(',')
    //   var mime = arr[0].match(/:(.*?);/)[1]
    //   var bstr = atob(arr[1])
    //   var n = bstr.length
    //   var u8arr = new Uint8Array(n)
    //   while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n)
    //   }
    //   return new Blob([u8arr], { type: mime })
    // },
    // handleCustomMatcher(node, Delta) {
    //   if (node.tagName === 'IMG'&&node.src.indexOf('data:image')>=0) {
    //     const quill = this.$refs.fieldEditor.quill
    //     if (quill) {
    //       const cursorLocation = quill.getSelection(true).index
    //       Delta.ops.forEach((op) => {
    //         if (op.insert) {

    //           var formData = new FormData()
    //           formData.append('file', this.toBlob(op.insert.image))

    //           axios({
    //             url: this.uploadUrl,
    //             method: 'POST',
    //             headers: { Authorization: this.authorization },
    //             data: formData
    //           })
    //           .then((result) => {
    //             quill.insertEmbed(cursorLocation, 'image', result.data.data)
    //             quill.setSelection(cursorLocation + 1)
    //             quill.update();
    //           })
    //           .catch((err) => {
    //             console.log(err)
    //           })
    //         }
    //       })
    //     }

    //     Delta.ops=[];
    //     return Delta
    //   } else {
    //     return Delta
    //   }
    // },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData()
      formData.append('file', file)

      const uploading = () => {
        let length = Editor.getSelection().index;
        Editor.insertText(
          length,
          '[上传中...]',
          { color: '#42BE9D' },
          true
        );
      };
      /**
       * 上传失败
       */
      const uploadError = () => {
        Editor.root.innerHTML = Editor.root.innerHTML.replace(
          /\[上传中.*?\]/,
          '[上传中失败]'
        );
      };
      const uploadSuccess = () => {
        Editor.root.innerHTML = Editor.root.innerHTML.replace(
          /\[上传中.*?\]/,
          ''
        );
      };
      const progress = (pro) => {
        // console.log("progress",pro);
        // let persent = (pro.loaded / pro.total * 100 | 0)
        let persent=pro;
        persent = '[' + '上传中 ' + persent + '%]';
        Editor.root.innerHTML = Editor.root.innerHTML.replace(
          /\[上传中.*?\]/,
          persent
        );
      };


      uploading();

      const aliOssUpload=aliUploader(false,{
          formId:this.formId||'formId',
          agencyId:this.agencyId,
          uploadPath: this.uploadPath,
          uploadProgressHandler:(percent)=>{
              progress(percent)
          }
      });


      aliOssUpload.elFileUpload({file}).then(uploadResult=>{
        console.log("uploadResult",uploadResult)
        Editor.insertEmbed(cursorLocation, 'image', encodeFileURI(uploadResult));
        uploadSuccess();
        resetUploader();
      })
      .catch(err => {
         uploadError();
      })

    },
    handleImagePaste() { },
    onInput(data) {
      this.$emit('input', data);
      this.$emit('count', this.$refs.fieldEditor.quill.getWordCount());
    },
    onTxtChanged(data) {
      // console.log(data);
    }
  }
}
</script>
<style lang="scss" scoped>
.quillWrapper {
  ::v-deep .ql-toolbar.ql-snow{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #DCDFE6;
  }
  ::v-deep .ql-container.ql-snow {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #DCDFE6;
  }
}

</style>
