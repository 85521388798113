import request from "@/utils/request";
import { isBlank } from "@/utils/whale";
import { getToken } from "../../utils/auth";
import { cleanObject } from "@/utils/deepClone";

export function saveFormDesign(data) {
  data.fieldConfig = encodeURIComponent(JSON.stringify(data.fieldConfig));
  return request({
    url: "/project-design",
    method: "post",
    data,
  });
}

export function getFormDesign(type, id) {
  return new Promise((resolve) => {
    request({
      url: `/project-design/${type}/${id}`,
      method: "get",
    }).then((res) => {
      if(!res.data || !res.data.fieldConfig){ resolve({fieldConfig:[]}); return;} 
      console.log('get page', decodeURIComponent(res.data.fieldConfig))
      if (typeof res.data.fieldConfig == "string") {
        res.data.fieldConfig = JSON.parse(decodeURIComponent(res.data.fieldConfig )|| null);
      } else {
        res.data.fieldConfig = decodeURIComponent(res.data.fieldConfig);
      }
      resolve(res.data);
    });
  });
}
