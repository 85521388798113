<template>
    <div class="color-view-wrapper">
        <div class="color-view" :class="[color==currentColor?'active':'']" :style="{borderColor:color}" v-for="(color,index) in colors" :key="index" @click.stop="selectColor(color)">
            <div class="round-color" :style="{backgroundColor:color}"></div>
        </div>
        <div class="color-view-empty"></div>
        <div class="color-view-empty"></div>
        <div class="color-view-empty"></div>
        <div class="color-view-empty"></div>
    </div>
</template>
<script>
export default {
  props: {
    value:{
        type:Object,
        default:"#aaaaaa"
    }
  },
  data() {
    return {
      expandPop: false,
      currentColor:"#aaaaaa",
      colors: ["#2196F3", "#08C9C9", "#00C345","#FAD714","#FF9300","#F52222","#EB2F96","#7500EA","#2D46C4","#484848",
               "#C9E6FC","#C3F2F2","#C2F1D2","#FEF6C6","#FFE5C2","#FDCACA","#FACDE6","#DEC2FA"],
      fontColors: ["#FFFFFF", "#FFFFFF", "#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF","#FFFFFF",
                    "#1b7ecd", "#079f9f", "#02a33b","#dfc011","#e78705","#d81f1f","#d52b88","#6a00d5"],
    };
  },
  watch:{
      value:function(val){
        if(val){
          this.currentColor=val.bgColor;
          this.$forceUpdate();
        }
      }
  },
  mounted(){
    if(this.value){
      this.currentColor=this.value.bgColor;
    }      

  },
  methods: {
    selectColor(color){
        this.currentColor=color;
        this.expandPop=false;

        let i=this.colors.findIndex(x=>x==color);
        this.$emit("input",{bgColor:color,fontColor:this.fontColors[i]});
    }   
  },
};
</script>
<style lang="scss" scoped>
  .color-view-wrapper{
      width:100%;
      padding-bottom: 0px;
      box-sizing: border-box;

      display: inline-flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position:relative;
      
      background: white;
  }
  .color-view {
    width: 24px;
    height: 24px;
    margin:2px;
    margin-bottom:6px;
    border-radius: 50%;
    box-sizing: border-box;
    border:solid 1px transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border:none 2px transparent;

    &:hover,&.active{
        border:solid 2px transparent;
        border-color: initial;
    }

    .round-color{
      width:16px;
      height:16px;

      border-radius: 50%;
      display:inline-block;
    }
    
    &-empty{
        width: 24px;
        height:0px;
        margin-left:5px;
        margin-right:5px;
        margin:1px;
        display: inline-block;
    }
  }
</style>
