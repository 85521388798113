var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-editor", {
    ref: "fieldEditor",
    attrs: {
      useCustomImageHandler: "",
      value: _vm.value,
      editorOptions: _vm.editorSettings,
      "editor-toolbar": _vm.customToolbar,
    },
    on: {
      "image-added": _vm.handleImageAdded,
      input: _vm.onInput,
      "text-change": _vm.onTxtChanged,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }