<template>
  <div style="width:100%;">
    <template v-if="!readonly">
      <el-dropdown placement="bottom-start" click="el-dropdown" trigger="click" :hide-on-click="false" style="width:100%;overflow:hidden">
        <span class="el-dropdown-link">
          <template v-if="multiple">
            <span class="selected-tag-empty" v-if="selectedTags.length == 0 && !readonly">请选择标签</span>
            <el-tag class="selected-tag" size="mini" v-for="(tag, index) in selectedTags" :closable="!readonly" :label="tag" :color="tag.color" :style="{ color: tag.fontColor }" :key="index" @close="removeSelectedTag(index)">{{ tag.name }}</el-tag>
          </template>
          <template v-else>
            <span class="selected-tag-empty" v-if="!selectedRadio&&!readonly">请选择标签</span>
            <el-tag class="selected-tag" size="mini" v-for="(tag, index) in selectedTags" :closable="!readonly" :label="tag" :color="tag.color" :style="{ color: tag.fontColor }" :key="index" @close="removeSelectedTag(index)">{{ tag.name }}</el-tag>
          </template>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <div style="width:200px;display:inline-block;overflow:hidden;position:relative;">
            <div class="pannel-select">
              <template v-if="multiple">
                <el-checkbox-group v-model="checkList" @change="updateCheckList">
                  <div style="padding: 0px 10px">
                    <el-input placeholder="" v-model="searchTag" size="mini" prefix-icon="el-icon-search"></el-input>
                    <div style=" max-height: 170px; overflow-y: auto; padding: 5px 0px; margin: 5px 0px; box-sizing: border-box; ">
                      <el-dropdown-item v-for="(tag, index) in tags" :label="tag" :key="index" class="dropdown-item" v-show="tag.name.indexOf(searchTag)>=0">
                        <el-checkbox  :label="tag.name" :value="tag.name">
                          <span class="color" :style="{ backgroundColor: tag.color }"></span>
                          <span class="label">{{ tag.name }}</span>
                          <span class="icon" @click.stop="removeTag(index)" v-if="tag.remove&&!readonly && !selectonly"><i class="el-icon-close"></i></span>
                        </el-checkbox>
                      </el-dropdown-item>
                    </div>
                  </div>
                </el-checkbox-group>
              </template>
              <template v-if="!multiple">
                <el-radio-group v-model="selectedRadio" @change="updateRadio">
                  <div style="padding: 0px 10px">
                    <el-input placeholder="" v-model="searchTag" size="mini" prefix-icon="el-icon-search"></el-input>
                    <div style=" max-height: 170px; overflow-y: auto; padding: 5px 0px; margin: 5px 0px; box-sizing: border-box; ">
                      <el-dropdown-item v-for="(tag, index) in tags" :label="tag" :key="index" class="dropdown-item" v-show="tag.name.indexOf(searchTag)>=0">
                        <el-radio  :label="tag.name" :value="tag.name">
                          <span class="color" :style="{ backgroundColor: tag.color }"></span>
                          <span class="label">{{ tag.name }}</span>
                          <span class="icon" @click.stop="removeTag(index)" v-if="tag.remove&&!readonly && !selectonly"><i class="el-icon-close"></i></span>
                        </el-radio>
                      </el-dropdown-item>
                    </div>
                  </div>
                </el-radio-group>
              </template>

              <div class="empty-tags" v-if="tags.length==0">
                暂无标签
              </div>
              <div class="footer" v-if="!selectonly">
                <el-button type="primary" size="mini" @click="switchCreate">新建</el-button>
              </div>
            </div>
            <div class="pannel-create" :class="[pannel == 1 ? 'show' : '']">
              <div class="pannel-create-label">标签内容</div>
              <el-input v-model="newTagName" size="mini"></el-input>
              <div class="pannel-create-label">标签颜色</div>
              <colorSelector v-model="newTagColor"></colorSelector>
              <div class="footer">
                <el-button type="primary" size="mini" @click="saveCreate">保存</el-button>
                <el-button type="default" size="mini" @click="pannel = 0">取消</el-button>
              </div>
            </div>
          </div>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-else>
      <el-tag class="selected-tag" size="mini" v-for="(tag, index) in selectedTags" :label="tag" :color="tag.color" :style="{ color: tag.fontColor }" :key="index">{{ tag.name }}</el-tag>
    </template>
  </div>
</template>
<script>
import { values } from 'xe-utils'
import colorSelector from './color-selector.vue'
import {getTagListByUserId} from '@/api/system/form'
import { getSessionStorageObj } from "@/utils/db";
export default {
  components: { colorSelector },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    selectonly: {
      type: Boolean,
      default: false
    },
    multiple:{
      type:Boolean,
      default:true
    },
    value: {
      type: Array|String,
      default: () => {
        return []
      }
    },
    tagList:{
      type:Array,
      default:()=>{
        return null
      }
    }
  },
  watch: {
    value: function(val) {
      if(val){
        if(this.multiple){
          this.checkList = val.map(x=>x.name);
        }else{
          this.checkList=[val];
        }
      }else{
          this.checkList=[];
      }
      this.$forceUpdate();
    },
    tagList:function(val){
       if(val!=null){
        let tags=[];

        val.forEach(tagItem=>{
            if(this.fixedTags.findIndex(x=>x.name==tagItem.name)==-1){
              tags.push(tagItem);
            }
        });

          this.tags=[...this.fixedTags,...tags];
      }
    },
  },
  mounted() {
    if (this.value) {
      if(this.multiple){
        this.checkList = this.value.map(x=>x.name);
      }else{
        this.checkList=[this.value];
      }
    }
  },
  async created() {

    let tags=[];
    if(this.tagList==null){
      
      this.loginUserId = getSessionStorageObj("users")?.userId;
      if(this.loginUserId){
        let tag=await getTagListByUserId();

        if(tag.data.tagList==null) return;
        //去重固定标签
        tag.data.tagList.forEach(tagItem=>{
            if(this.fixedTags.findIndex(x=>x.name==tagItem.name)==-1){
              tags.push(tagItem);
            }
        })
      }

    }else{
      this.tagList.forEach(tagItem=>{
          if(this.fixedTags.findIndex(x=>x.name==tagItem.name)==-1){
            tags.push(tagItem);
          }
      })
    }
      this.tags=[...this.fixedTags,...tags];
      this.$forceUpdate();
  },
  data() {
    return {
      checkList: [],
      selectedRadio:"",
      pannel: 0,
      newTagName: '',
      newTagColor: {},
      searchTag:"",
      tags: [],
      fixedTags : [
        {
          name: '数据收集',
          color: '#00C345',
          fontColor: '#FFFFFF',
          remove: false
        },
        {
          name: '会议活动',
          color: '#FAD714',
          fontColor: '#FFFFFF',
          remove: false
        },
        {
          name: '项目申报',
          color: '#F52222',
          fontColor: '#FFFFFF',
          remove: false
        }
      ]
    }
  },
  computed: {
    selectedTags: function() {
      let tags=[];
      this.checkList.forEach(selectedTagName=>{
        let tag=this.tags.find(x=>x.name==selectedTagName)
        if(tag){
          tags.push(tag)
        }
      });
      return tags;
    }
  },
  methods: {
    removeSelectedTag: function(i) {
      if(this.multiple){
        this.checkList.splice(i, 1)
        this.$emit('input', this.selectedTags)
      }else{
        this.selectedRadio="";
        this.$emit('input', this.selectedRadio)
      }
    },
    removeTag: function(i) {
      this.tags.splice(i, 1)
    },
    updateCheckList: function() {
      this.$emit('input', this.selectedTags)
    },
    updateRadio:function(){
      this.$emit('input', this.selectedRadio)
    },
    switchCreate: function() {
      this.pannel = 1
    },
    saveCreate: function() {
      if (this.newTagName != '') {
        let newTag={
          name: this.newTagName,
          color: this.newTagColor.bgColor,
          fontColor: this.newTagColor.fontColor
        }
        this.tags.push(newTag)
        this.selectedTags.push(newTag);
        this.checkList.push(this.newTagName);

      this.$emit('input', this.selectedTags)
      }
      this.newTagName = ''
      this.pannel = 0
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dropdown{
  height: 32px;
  line-height: 32px;
}
.el-dropdown-link {
  width:100%;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  padding: 0px 10px;
  text-align: left;
  border-radius: 3px;
  display: inline-flex;
  // align-items: center;
  box-sizing: border-box;
  // border: solid 1px transparent;
  border: solid 1px #dcdfe6;
  display: inline-block;

  &:hover {
    // border: solid 1px #dcdfe6;
    .el-icon-arrow-down {
      display: inline-block;
    }
  }
  .selected-tag {
    border: none 0px;
    margin:0px;
  }
  .selected-tag + .selected-tag {
    margin-left: 5px;
  }
  .selected-tag-empty {
    padding: 0px 5px;
    font-size: 14px;
    width:90%;
    color:#CBC7D5;
  }
}

.el-icon-arrow-down {
  margin-left: 5px;
  display: inline-block;
  float: right;
  margin-top: 8px;
}
::v-deep .el-tag--mini{
  line-height:20px;
  border:none 0px;
  margin-left:5px;
}
::v-deep .el-tag .el-tag__close {
  color: #ffffffcc;
  &:hover {
    color: #ffffffff;
    background-color: #ffffff88;
  }
}
.dropdown-item {
  height: 30px !important;
  line-height: 30px !important;
}
.el-dropdown-menu--medium {
  margin-top: 0px !important;
  padding-bottom: 0px;
  overflow: hidden;
}
.el-dropdown-menu--medium .el-dropdown-menu__item {
  line-height: 30px;
  padding: 0 2px;
}

.pannel-select {
  min-height: 230px;
  padding-bottom: 40px;
  padding-top: 5px;

  ::v-deep .el-checkbox__label {
    display: inline-flex !important;
    width: 100% !important;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    align-items: center;
  }

  .el-checkbox,.el-radio {
    width: 100%;
    display: inline-flex;
    align-items: center;

    span {
      display: inline-block;
    }

    .color {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      background-color: #dcdfe6;
    }
    .label {
      flex-grow: 1;
    }
    i {
      display: none;
    }
    &:hover {
      i {
        display: inline-block;
      }
    }
  }
  .footer {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 5px 10px;
    border-top: solid 1px #e5e5e5;
  }
}

.pannel-create {
  position: absolute;
  left: 200px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 200px;
  transition: left 0.4s ease-in-out;
  background-color: white;
  z-index: 1;
  padding: 10px;
  border-radius: 3px;
  box-sizing: border-box;
  &.show {
    left: 0px;
  }
  &-label {
    height: 28px;
    line-height: 28px;
  }
  .footer {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 5px 10px;
    border-top: solid 1px #e5e5e5;
  }
}
.empty-tags{
  width:100%;
  text-align: center;
  font-size:14px;
  color:#bababa;
  user-select: none;
}
</style>
